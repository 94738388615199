<template>
  <CBox v-if="isLoadingPage || errorMessage === 'club not found'"
    ><Loading :active="isLoadingPage"
  /></CBox>
  <CBox
    v-else-if="currentClub.role === 'owner' || currentClub.role === 'admin'"
    w="full"
    d="flex"
    justifyContent="space-between"
    flexWrap="wrap"
  >
    <CBox
      borderRadius="10px"
      mt="5"
      :w="['100%', '100%', '74%', '74%']"
      bg="white"
      shadow="sm"
      rounded="md"
      py="24px"
      :px="['10px', '24px', '24px', '24px']"
      d="relative"
    >
      <ImageCroppie
        :isModalOpen="isModalOpen"
        :urlImage="logoPreview"
        @closeModal="closeModal"
        @cropCompleted="handleCropCompleted"
        @cropBlob="handleCropBlob"
      />
      <CBox>
        <CText fontStyle="extrabold" fontSize="3xl" fontWeight="semibold"
          >Edit Club</CText
        >
        <form method="post">
          <CBox px="4">
            <CFormControl my="4">
              <CFormLabel>Profile</CFormLabel>
              <CInputGroup>
                <CInput
                  type="file"
                  accept="image/*"
                  @change.native="handleImageUpload"
                  required
                  d="none"
                  ref="fileInput"
                  id="fileInput"
                />
                <CBox>
                  <CFormLabel
                    for="fileInput"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    position="relative"
                  >
                    <CImage
                      :src="
                        croppedImageData
                          ? croppedImageData
                          : params.data.logo
                          ? params.data.logo
                          : require('@/assets/img/empty-file-image.svg')
                      "
                      boxSize="150px"
                      borderRadius="5"
                      w="10"
                    />
                    <CImage
                      :src="require('@/assets/icon/icon-plus.svg')"
                      mr="1"
                      position="absolute"
                      right="0"
                      bottom="0"
                      fontSize="12px"
                      color="white"
                      bg="bgButtonClub"
                      borderRadius="full"
                      w="20px"
                    />
                  </CFormLabel>
                  <CFormHelperText textAlign="center">
                    (300 x 300)
                  </CFormHelperText>
                  <CFormHelperText textAlign="center">
                    Maximum file size: 2Mb
                  </CFormHelperText>
                </CBox>
                <CFormHelperText
                  v-if="fileSize > 1600000"
                  color="red.300"
                  fontSize="sm"
                  ml="2"
                  my="auto"
                >
                  File size too large
                </CFormHelperText>
              </CInputGroup>
            </CFormControl>
            <CFormControl id="name" mb="4">
              <CFormLabel>Name</CFormLabel>
              <CInput v-model="params.data.name" required />
              <CFormHelperText
                :color="params.data.name.length > 50 ? '#E82609' : '#ACACAC'"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  params.data.name.length > 50
                    ? "Name too long."
                    : "Maximum 50 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl id="location" mb="4">
              <CFormLabel>Location</CFormLabel>
              <CInput v-model="params.data.location" required />
              <CFormHelperText
                :color="
                  params.data.location.length > 50 ? '#E82609' : '#ACACAC'
                "
                maxW="300px"
                fontSize="12px"
              >
                {{
                  params.data.location.length > 50
                    ? "Location too long."
                    : "Maximum 50 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl id="description" mb="4">
              <CFormLabel>Description</CFormLabel>
              <textarea
                v-model="params.data.description"
                rows="3"
                class="textarea-club"
                required
              ></textarea>
              <CFormHelperText
                :color="
                  params.data.description.length > 1500 ? '#E82609' : '#ACACAC'
                "
                maxW="300px"
                fontSize="12px"
              >
                {{
                  params.data.description.length > 1500
                    ? "Description too long."
                    : "Maximum 1500 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl id="rule" mb="4">
              <CFormLabel>Rule to join this club</CFormLabel>
              <textarea
                v-model="params.data.rule"
                rows="3"
                class="textarea-club"
                required
              ></textarea>
              <CFormHelperText
                :color="params.data.rule.length > 1500 ? '#E82609' : '#ACACAC'"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  params.data.rule.length > 1500
                    ? "Rule too long."
                    : "Maximum 1500 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="type" d="block" m="0" p="0">Type</CFormLabel>
              <CButton
                v-for="(type, i) in types"
                :key="i"
                :bg="params.data.type === type ? '#E1F3FF' : 'white'"
                :color="params.data.type === type ? '#29AAFE' : 'black'"
                @click.native="setType(type)"
                :_hover="{ opacity: 0.8 }"
                :border="
                  params.data.type === type
                    ? '#29AAFE 1px solid'
                    : '#EFF1F5 1px solid'
                "
                mr="2"
                mt="2"
                mb="2"
                fontWeight="400"
              >
                <CImage
                  :src="
                    params.data.type === type
                      ? require('@/assets/icon/icon-' +
                          checkType(type).toLowerCase() +
                          '-active.svg')
                      : require('@/assets/icon/icon-' +
                          checkType(type).toLowerCase() +
                          '.svg')
                  "
                  mr="2"
                  w="20px"
                />
                {{ checkType(type) }}</CButton
              >
            </CFormControl>
            <CFormControl mb="4" :w="['100%', '50%', '50%', '50%']">
              <CFormLabel for="language" m="0" p="0">Language</CFormLabel>
              <CText color="#ACACAC" maxW="300px" fontSize="12px" mb="2">
                Select one or more options
              </CText>
              <CBox>
                <CButton
                  v-for="(language, i) in languages"
                  :key="i"
                  :bg="
                    params.data.language.includes(language)
                      ? '#E1F3FF'
                      : 'white'
                  "
                  :color="
                    params.data.language.includes(language)
                      ? '#29AAFE'
                      : 'black'
                  "
                  @click.native="setLanguage(language)"
                  :_hover="{ opacity: 0.8 }"
                  :border="
                    params.data.language.includes(language)
                      ? '#29AAFE 1px solid'
                      : '#EFF1F5 1px solid'
                  "
                  mr="1"
                  mt="1"
                  mb="1"
                  fontWeight="400"
                >
                  <CImage
                    :src="
                      require('@/assets/icon/icon-' +
                        language.toLowerCase().replace('#', 's') +
                        '.svg')
                    "
                    mr="2"
                    w="20px"
                  />
                  {{ language }}</CButton
                >
              </CBox>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="slug">Slug</CFormLabel>
              <CBox
                :d="['block', 'flex', 'flex', 'flex']"
                alignItems="center"
                :bg="['', '#EFF1F5', '#EFF1F5', '#EFF1F5']"
                w="fit-content"
                :pl="['0', '4', '4', '4']"
                borderRadius="5"
              >
                <CText
                  :fontSize="['12px', '16px', '16px', '16px']"
                  mr="2"
                  fontWeight="300"
                  >https://devcode.gethired.id/clubs/</CText
                >
                <CInput
                  id="slug"
                  v-model="formattedLink"
                  required
                  w="250px"
                  :mt="['2', '0', '0', '0']"
                  placeholder="your-slug"
                />
              </CBox>
              <CFormHelperText
                v-if="
                  params.data.link.length > 50 || params.data.link.length <= 3
                "
                color="#ACACAC"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  params.data.link.length > 50
                    ? "Slug too long."
                    : 3 >= params.data.link.length
                    ? "Slug must be 3 characters or longer."
                    : ""
                }}
              </CFormHelperText>
              <CFormHelperText color="red.300" fontSize="12px">
                {{
                  errorMessage === "link already exist"
                    ? "Slug already exist."
                    : errorMessage === "link must be alphabet characters only"
                    ? "Slug must be alphabet only."
                    : ""
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CBox
                :d="['block', 'flex', 'flex', 'flex']"
                alignItems="center"
                w="fit-content"
              >
                <CCheckbox
                  :value="params.data.private"
                  :isChecked="params.data.private"
                  @change="setPrivate(params.data.private)"
                  d="flex"
                  alignItems="center"
                >
                  Make your club private
                  <CImage
                    :src="require('@/assets/icon/icon-lock.svg')"
                    w="20px"
                    d="inline-block"
                  />
                </CCheckbox>
              </CBox>
            </CFormControl>
            <CFormControl mb="4">
              <CBox
                :d="['block', 'flex', 'flex', 'flex']"
                alignItems="center"
                w="fit-content"
              >
                <CCheckbox
                  :value="!params.data.is_need_approval"
                  :isChecked="!params.data.is_need_approval"
                  @change="setIsNeedApproval(params.data.is_need_approval)"
                  d="flex"
                  alignItems="center"
                >
                  Accept Membership Requests Automatically
                </CCheckbox>
              </CBox>
            </CFormControl>
            <CButton
              bg="bgButtonClub"
              color="white"
              fontWeight="600"
              :_hover="{ opacity: '0.8' }"
              :disabled="isCreateClubButtonDisabled || isLoading"
              @click.native="putData(currentClub.id)"
              >{{ !isLoading ? "Save Changes" : "Loading..." }}</CButton
            >
          </CBox>
          <CBox
            v-if="currentClub.role === 'owner'"
            bg="#faebcc"
            d="flex"
            :flexDirection="['column', 'row', 'row', 'row']"
            alignItems="center"
            px="16px"
            py="8px"
            mt="20px"
            w="fit-content"
          >
            <CText fontWeight="400" fontSize="12px">
              This action will remove your club and your club's history.
            </CText>
            <CButton
              :mt="['8px', '0', '0', '0']"
              :ml="['0', '8px', '8px', '8px']"
              bg="black"
              color="white"
              w="fit-content"
              fontSize="12px"
              size="0"
              fontWeight="400"
              px="12px"
              py="6px"
              :_hover="{ opacity: '0.8' }"
              @click.native="deleteData(currentClub.slug)"
              >Delete Club</CButton
            >
          </CBox>
        </form>
      </CBox>
    </CBox>
    <CBox
      v-if="!isLoading && errorMessage !== 'club not found'"
      :w="['100%', '100%', '25%', '25%']"
    >
      <SidebarDetailClub />
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import {
  CBox,
  CText,
  CFormControl,
  CFormLabel,
  CInput,
  CButton,
  CFormHelperText,
  CImage,
  CCheckbox,
} from "@chakra-ui/vue";
import Swal from "sweetalert2";
import {
  GET_CLUB_DETAIL,
  PUT_CLUB,
  DELETE_CLUB,
  POST_CLUB_IMAGE,
} from "@/store/club.module";
import SidebarDetailClub from "@/components/sidebar/DetailClub.vue";
import ImageCroppie from "@/components/modal/ImageCroppie.vue";
import config from "@/ConfigProvider.js";

export default {
  metaInfo() {
    return {
      title: "Edit Club",
      titleTemplate: "Devcode - %s",
    };
  },
  name: "EditClub",
  components: {
    SidebarDetailClub,
    CBox,
    CText,
    CFormControl,
    CFormLabel,
    CInput,
    CButton,
    CFormHelperText,
    CImage,
    CCheckbox,
    ImageCroppie,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      isLoadingPage: true,
      logoPreview: "",
      fileSize: 0,
      isPrivate: true,
      params: {
        id: "",
        data: {
          logo: "",
          name: "",
          location: "",
          type: "komunitas",
          link: "",
          description: "",
          rule: "",
          language: [],
          private: false,
          is_need_approval: false,
        },
      },
      languages: [
        "Javascript",
        "PHP",
        "Go",
        "Python",
        "Java",
        "C#",
        "Rust",
        "Ruby",
        "Dart",
        "Kotlin",
        "C",
        "C++",
        "Typescript",
      ],
      types: ["komunitas", "tim lomba", "kampus", "perusahaan"],
      errorMessage: "",
      cropperOptions: 2,
      isModalOpen: false,
      isUrlCopied: false,
      croppedImageData: null,
      croppedImageBlob: null,
      urlGethired: config.value("GETHIRED_WEB_URL"),
    };
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods: {
    async putData(id) {
      try {
        this.isLoading = true;

        if (this.croppedImageBlob) {
          const formData = new FormData();
          formData.append("file", this.$refs.fileInput);

          const imageResponse = await this.$store.dispatch(
            POST_CLUB_IMAGE,
            this.croppedImageBlob
          );

          this.params.data.logo = imageResponse.data.location;
        }

        this.params.id = id;

        if (this.currentUser) {
          await this.$store.dispatch(PUT_CLUB, this.params);
          Swal.fire({
            title: "Updated!",
            text: "Club updated successfully!",
            icon: "success",
          }).then(() => {
            this.$router.push("/clubs/" + this.params.data.link).then(() => {
              setTimeout(() => {
                window.location.reload(true);
              }, 0);
            });
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
        } else {
          console.error("Unexpected error:", error.message);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getData() {
      try {
        const params = {
          slug: this.$route.params.slug,
        };
        await this.$store.dispatch(GET_CLUB_DETAIL, params);

        this.params.data = {
          name: this.currentClub.name,
          location: this.currentClub.location,
          type: this.currentClub.type,
          link: this.currentClub.slug,
          description: this.currentClub.description,
          rule: this.currentClub.rule,
          language: this.currentClub.language,
          private: this.currentClub.is_private,
          is_need_approval: this.currentClub.is_need_approval,
        };
        if (this.currentClub.logo) {
          this.params.data.logo = this.currentClub.logo;
        } else {
          this.params.data.logo = "";
        }

        if (
          this.currentClub.role !== "owner" &&
          this.currentClub.role !== "admin"
        ) {
          Swal.fire({
            title: "Oops!",
            text: "You don't have access to this page!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
          Swal.fire({
            title: "Error!",
            text: "Club not found!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        }
      } finally {
        this.isLoadingPage = false;
      }
    },
    deleteData(id) {
      if (this.currentUser) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.params.id = id;
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              }).then(() => {
                this.$router.push("/clubs").then(() => {
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 0);
                });
              });
              await this.$store.dispatch(DELETE_CLUB, this.params);
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
    isFormComplete() {
      return (
        this.params.data.name &&
        this.params.data.name?.length <= 50 &&
        this.params.data.location &&
        this.params.data.location?.length <= 50 &&
        this.params.data.description &&
        this.params.data.description?.length <= 1500 &&
        this.params.data.rule &&
        this.params.data.rule?.length <= 1500 &&
        this.params.data.link?.length > 3 &&
        this.params.data.link?.length <= 50 &&
        this.params.data.language?.length > 0
      );
    },
    async handleImageUpload(event) {
      const file = event.target.files[0];
      if (file && file.size && file.size <= 1600000) {
        this.fileSize = file.size;

        this.logoPreview = URL.createObjectURL(file);

        const formData = new FormData();
        formData.append("file", file);

        this.isModalOpen = true;
      } else if (file.size > 1600000) {
        this.fileSize = file.size;
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleCropCompleted(croppedData) {
      this.croppedImageData = croppedData;
    },
    handleCropBlob(croppedImageBlob) {
      this.croppedImageBlob = croppedImageBlob;
    },
    setType(type) {
      this.params.data.type = type;
    },
    setPrivate(isPrivate) {
      this.params.data.private = !isPrivate;
    },
    setIsNeedApproval(isNeedApproval) {
      this.params.data.is_need_approval = !isNeedApproval;
    },
    setLanguage(language) {
      if (this.params.data.language.includes(language)) {
        const index = this.params.data.language.indexOf(language);
        this.params.data.language.splice(index, 1);
      } else {
        this.params.data.language.push(language);
      }
    },
    checkType(type) {
      return type === "komunitas"
        ? "Community"
        : type === "tim lomba"
        ? "Event"
        : type === "kampus"
        ? "University"
        : "Company";
    },
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    currentSlug() {
      return this.$route.params.slug;
    },
    currentClub() {
      return this.$store.getters.currentClub || [];
    },
    isCreateClubButtonDisabled() {
      return !this.isFormComplete();
    },
    formattedLink: {
      get() {
        return this.params.data.link
          .replace(/[^a-zA-Z0-9]/g, "-")
          .toLowerCase();
      },
      set(value) {
        this.$set(
          this.params.data,
          "link",
          value.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
        );
      },
    },
  },
};
</script>
